import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { requiredValidator } from '@elevate/forms';

export interface FundingPaymentFormType {
  fundingPaymentMethod: string;
}
export interface validations {
  requiredValidationMessage: string;
  defaultValue: string;
}

@Injectable()
export class FundingPaymentSelectionForm extends PageFormGroup {
  constructor(validation: validations) {
    super({
      fundingPaymentMethod: new FormControl(null, [
        requiredValidator(validation.requiredValidationMessage)
      ])
    });
  }
}
