<div
  #consentContainer
  [id]="getConsentContainerId()"
  class="consents"
  *ngIf="consentSectionItems"
>
  <hr
    *ngIf="!hideDividingLine && formContainsConsents"
    class="section-separater"
  />

  <div
    *ngFor="let consentSectionItem of consentSectionItems"
    [formGroup]="formGroup"
  >
    <div *ngIf="isConsentNeeded(consentSectionItem)" class="consent-check">
      <ecl-checkbox
        [id]="getConsentEclCheckboxId(getConsent(consentSectionItem.consent))"
        [formControlConfig]="
          getConsentControlConfig(consentSectionItem.consent)
        "
        [formControlName]="
          getConsentControlName(getConsent(consentSectionItem.consent))
        "
        (change)="onCheckboxChange(consentSectionItem.consent)"
      >
        <span
          [innerHTML]="
            getConsent(consentSectionItem.consent).checkboxLabel | safe: 'html'
          "
        ></span>
      </ecl-checkbox>
      <div
        [innerHTML]="getConsent(consentSectionItem.consent).text | safe: 'html'"
      ></div>
      <div
        [id]="
          getConsentEclCheckboxId(getConsent(consentSectionItem.consent)) +
          'Footer'
        "
        class="consent-footer"
        [innerHTML]="
          getConsent(consentSectionItem.consent).footer | safe: 'html'
        "
      ></div>
    </div>
    <div *ngIf="consentSectionItem.text" class="consent-notice-below">
      <div [innerHTML]="consentSectionItem.text | safe: 'html'"></div>
    </div>
  </div>
</div>
