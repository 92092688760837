import {
  Consent,
  ConsentModal,
  ConsentSectionItem,
  ConsentMapping
} from './consents.content';
import { FormGroup } from '@angular/forms';
import {
  ConsentModalAgreement,
  SignatureHelper,
  SignatureModel
} from './e-signature/signature.content';

export class ConsentHelper {
  private static readonly consentControlNameStart = 'consentControl_';

  public static getConsentControlName(
    consent: Consent
  ): string {
    const nameid = `${consent.key.charAt(0).toLowerCase()}${consent.key.slice(
      1
    )}`;
    return nameid;
  }

  public static formContainsConsents(consents: Consent[]): boolean {
    return consents.length > 0;
  }

  public static confirmAllConsents(
    form: FormGroup,
    consents?: Consent[]
  ): void {
    consents.forEach(controlName => {
      const key = `${controlName.key
        .charAt(0)
        .toLowerCase()}${controlName.key.slice(1)}`;
      form.get(key).setValue(true);
    });
  }

  public static getStateConsentIndex(
    consentsSection: ConsentSectionItem[],
    key: string
  ): number {
    return consentsSection.findIndex(
      x =>
        x.consent?.statesConsents?.some(y => y.consent?.key === key) ||
        x.consent?.defaultConsent?.key === key
    );
  }

  /**
   * Generate disclosure.key that is sending to the server. Use this method if consent has no signatures.
   */
  public static generateDisclosureKeyByConsent(consent: Consent): string {
    return this.generateDisclosureKeyByAgreement(consent, null);
  }

  /**
   * Generate disclosure.key that is sending to the server by alredy signed signature (SignatureModel).
   */
  public static generateDisclosureKeyBySignature(
    consent: Consent,
    signature: SignatureModel
  ): string {
    const agreements = this.getConsentAgreements(consent);
    // in order to not confuse how we generate discklosure.key (that is sent to server)
    // we firstly find agreement (from ContentStack) by signature (it's structure that contains already signed one of e-sigantures)
    // and use the same method to generate disclosure.key
    const signatureAgreement = agreements.find(
      agreement =>
        signature.controlName ===
        SignatureHelper.getSignatureControlName(agreement)
    );
    return this.generateDisclosureKeyByAgreement(consent, signatureAgreement);
  }

  /**
   * Generate disclosure.key that is sending to the server
   * @param agreement - can be null if consent has no signatures
   */
  public static generateDisclosureKeyByAgreement(
    consent: Consent,
    agreement: ConsentModalAgreement = null
  ): string {
    if (agreement == null) {
      // If consent has zero or one agreement - we will send 'Consent.Key' (filled in ContentStack) as disclosure key
      return consent.key;
    }
    const agreements = this.getConsentAgreements(consent);
    if (agreements.length <= 1) {
      // If consent has zero or one agreement - we will send 'Consent.Key' (filled in ContentStack) as disclosure key
      return consent.key;
    } else {
      // If consent has more than one agreement - we will send 'Consent.Key_Agreement.Name' (filled in ContentStack) as disclosure key
      // Can be updated to use only 'Agreement.Name'
      return consent.key + '_' + agreement.name;
    }
  }

  public static getConsentAgreements(
    consent: Consent
  ): ConsentModalAgreement[] {
    let result = [];
    consent?.disclosureModals?.forEach(consentModal => {
      result = result.concat(this.getConsentModalAgreements(consentModal));
    });
    return result;
  }

  public static getConsentModalAgreements(
    consentModal: ConsentModal
  ): ConsentModalAgreement[] {
    const result = [];
    consentModal?.content?.forEach(modalContent => {
      if (modalContent?.agreement != null) {
        result.push(modalContent.agreement);
      }
    });
    return result;
  }

  private static getConsentIndex(
    items: ConsentSectionItem[],
    consent: ConsentMapping
  ): number {
    return items.findIndex(x => x.consent === consent);
  }

  public static converToArray(entity: any): any {
    if (!entity) {
      return [];
    }
    if (entity && !Array.isArray(entity)) {
      return [entity];
    } else {
      return entity;
    }
  }
}
