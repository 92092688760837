import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  SecurityContext,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  afterRender
} from '@angular/core';
import { FundingPaymentSelectionForm } from './funding-payment-selection.form';
import { ApplicationDataService } from '@application/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';
import { RadioButtonComponent } from '@elevate/ui-components';
import {
  ApplicationData,
  ApplicationForm,
  FundMethodType
} from '@application/application';
import { CmsFundingPaymentSelection } from './funding-payment-selection-content';
import { RadioButton } from '@elevate/ui-components/lib/models/radio.model';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ConsentHelper } from '@application/consents/consent.helper';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
interface FundingPaymentOption {
  formControlConfig: RadioButton;
  description: string;
  disclosure: string;
}

@Component({
  selector: 'app-funding-payment-selection',
  templateUrl: './funding-payment-selection.component.html',
  styleUrls: ['./funding-payment-selection.component.scss'],
  providers: [FundingPaymentSelectionForm],
  encapsulation: ViewEncapsulation.None
})
export class FundingPaymentSelectionComponent implements OnInit, AfterViewInit {
  @ViewChildren(RadioButtonComponent) radioButtons: QueryList<
    RadioButtonComponent
  >;
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;

  public continueButton: string;
  public cmsPage: CmsFundingPaymentSelection;
  public fundPaymentOptions: FundingPaymentOption[];
  private bankAccNoPlaceholder = '{checkingAccountLast4Digits}';
  public consentsSection: ConsentSectionItem[];
  public form: FundingPaymentSelectionForm;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private cmsPageContentService: CmsPageContentService,
    private applicationFlowService: ApplicationFlowService
  ) {
    this.cmsPage = this.route.snapshot.data.cmsContent
      .fundingPaymentSelection as CmsFundingPaymentSelection;
    this.form = new FundingPaymentSelectionForm(this.cmsPage.validations);
    afterRender(() => {
      document.body.style.overflow = 'auto';
    });
  }

  public ngOnInit(): void {
    const appData = this.applicationDataService.getApplication();

    this.consentsSection = this.cmsPage.consentsSection || [];
    this.fillFundPaymentOptions(this.cmsPage);
    this.replaceBankAccNoPlaceholderWithData(appData);
    this.cmsPageContentService.updatePageTitle(this.cmsPage.header);
  }

  public ngAfterViewInit(): void {
    const defaultValue = this.cmsPage.validations?.defaultValue;
    if (defaultValue !== 'None') {
      this.radioButtons.changes.subscribe(comp => {
        comp
          .find(ele => ele.value === defaultValue)
          .radiobutton.nativeElement.click();
      });
      this.selectValue(defaultValue);
    }
  }

  public selectValue(fundingPaymentMethod: string): void {
    this.form.patchValue({ fundingPaymentMethod });
    this.radioButtons.forEach(element => {
      if (fundingPaymentMethod === element.value) {
        element.radioValue = 'true';
        element.radiobutton.nativeElement.click();
      }
    });
  }

  public onSubmit(): void {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const applicationForm: ApplicationForm = {
      fundMethod: this.form.value.fundingPaymentMethod as FundMethodType,
      paymentMethod: this.form.value.fundingPaymentMethod as FundMethodType,
      continuePath: this.applicationFlowService.getContinuePath(),
      disclosures: this.consentsComponent?.disclosures
    };

    this.applicationApi
      .append(applicationForm)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.applicationDataService.merge({ form: applicationForm });
          this.router.navigate([applicationForm.continuePath]);
        }
      });
  }

  private fillFundPaymentOptions(cmsPage: CmsFundingPaymentSelection): void {
    this.continueButton = cmsPage.buttons.continue;

    this.fundPaymentOptions = cmsPage.selectOptions.map(
      option =>
        ({
          formControlConfig: {
            id: `${option.value}Option`,
            name: 'fundingPaymentMethod',
            label: this.sanitizeHtml(option.name),
            value: option.value,
            attributes: {
              'data-nid-target': `fundingType${option.value}Option`
            }
          },
          description: this.sanitizeHtml(option.content),
          disclosure: this.sanitizeHtml(option.disclosure)
        } as FundingPaymentOption)
    );
  }

  private replaceBankAccNoPlaceholderWithData(appData: ApplicationData): void {
    const selectedBankAcct = appData.form.applicant.bank.accounts.find(
      (obj: any) => {
        return obj.key === appData.form.applicant.bank.draftFromKey;
      }
    );
    const electronicOption = this.fundPaymentOptions.find(_ =>
      _.description.includes(this.bankAccNoPlaceholder)
    );

    electronicOption.description = electronicOption.description.replace(
      this.bankAccNoPlaceholder,
      selectedBankAcct.accountNumberLast4
    );
  }

  private sanitizeHtml(html: string): string {
    return this.sanitizer.sanitize(SecurityContext.STYLE, html);
  }

  public get formContainsConsents(): boolean {
    return this.consentsSection?.length > 0;
  }
  
  public debugHudSubmitConsents(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
  }
}
