import { Component, OnInit, ViewChild } from '@angular/core';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { SignAndSubmitFormGroup } from './sign-and-submit.form';
import { SignAndSubmitDebugHudService } from './debug-hud/debug-hud.service';
import { ApplicationForm } from '../application';
import { ApplicationDataService } from '@application/application.service';
import { ApplicationApi } from '@application/application.api';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { SignAndSubmitContent } from './sign-and-submit-content';
import { ConsentModalAgreement } from '@application/consents/e-signature/signature.content';
import { ConsentsComponent } from '@application/consents/consents.component';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import {
  Environment,
  SubmitApplicationFlowOptions
} from '@environment/environment';
import { lastValueFrom, Subscription } from 'rxjs';
import * as moment from 'moment';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { LoanPaymentApiService } from '@application/underwriting/loan-api.service';

@Component({
  selector: 'app-sign-and-submit',
  templateUrl: './sign-and-submit.component.html',
  styleUrls: ['./sign-and-submit.component.scss'],
  providers: [SignAndSubmitFormGroup, SignAndSubmitDebugHudService]
})
export class SignAndSubmitComponent implements OnInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  public content: SignAndSubmitContent;

  public agreementConfig: ConsentModalAgreement = {
    label: 'Agree and Acknowledge',
    instruction: 'Enter your full name',
    type: 'Typed Name',
    validationRequired: 'Please enter your full name',
    validationNotMatch: 'Signature does not match',
    ignoreCase: true,
    ignoreSpaces: true
  } as ConsentModalAgreement;

  public formChangesSubscription: Subscription;
  public callSubmitApplication: boolean;

  constructor(
    public form: SignAndSubmitFormGroup,
    private pageHeaderService: CmsPageContentService,
    public signAndSubmitDebugHudService: SignAndSubmitDebugHudService,
    private applicationDataService: ApplicationDataService,
    private applicationApi: ApplicationApi,
    private router: Router,
    private loadingModal: LoadingModalService,
    private googleAnalytics: GoogleAnalytics,
    private neuroIdService: NeuroIdService,
    private activatedRoute: ActivatedRoute,
    private environment: Environment,
    private applicationFlowService: ApplicationFlowService,
    private loanPaymentApiService: LoanPaymentApiService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.content = this.activatedRoute.snapshot.data.cmsContent.signAndSubmit;
    this.agreementConfig = {
      type: this.agreementConfig.type,
      ignoreSpaces: this.agreementConfig.ignoreSpaces,
      ignoreCase: this.agreementConfig.ignoreCase,
      label: this.content.labels.signatureLabel,
      instruction: this.content.labels.signatureInstruction,
      validationNotMatch: this.content.validationMessages.signatureDoesNotMatch,
      validationRequired: this.content.validationMessages.signatureRequired
    } as ConsentModalAgreement;

    this.pageHeaderService.updatePageTitle(this.content.header);

    this.callSubmitApplication =
      this.environment.applicationFlowSettings.applicationSubmitStep ===
      SubmitApplicationFlowOptions.SignAndSubmit;
  }

  public async onSubmit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const nextPage = this.applicationFlowService.getContinuePath();

    const signAndSubmitApplicationPayload: ApplicationForm = {
      disclosures: this.consentsComponent?.disclosures
    };

    if(this.content?.labels?.signatureInputEnabled){
      Array.prototype.push.apply(
        signAndSubmitApplicationPayload.disclosures,
        [
          {
            key: 'AgreeAndAcknowledgeSignature',
            consentGiven: true,
            signature: this.form?.get('typedNameSignature')?.value
          }
        ]
      );
    }

    this.loadingModal.open();

    try {
      await lastValueFrom(
        this.loanPaymentApiService.signLoanAgreement(signAndSubmitApplicationPayload)
      );

      if (this.callSubmitApplication) {
        await lastValueFrom(this.applicationApi.submit());

        await lastValueFrom(
          this.applicationApi.append({ continuePath: nextPage })
        );
      }

      this.applicationDataService.merge({
        form: signAndSubmitApplicationPayload
      });

      this.neuroIdService.setCheckpoint('application_submission_checkpoint');
      this.googleAnalytics.setGoogleTagManagerVariables({
        application_complete_date: moment.utc().format()
      });

      this.router.navigate([nextPage]);
    } catch (error) {
      this.router.navigate(['/error']);
      this.loadingModal.close();
    }
  }

  public debugHudSign(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
    this.signAndSubmitDebugHudService.sign();
  }
}
