import { Injectable } from '@angular/core';
import { PageFormGroup } from '@core/page-form-group/page-form-group';

export interface VerifyInfo {
  creditReportDisclosure?: boolean;
  consentToCommunication?: boolean;
  consentToElectronics?: boolean;
  correctInformationConfirmation?: boolean;
  requestedAmount?: number;
}

@Injectable()
export class VerifyInfoFormGroup extends PageFormGroup {
  constructor() {
    super({});
  }
}
